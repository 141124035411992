import React, { useState, useMemo, useCallback, Fragment } from 'react'
import cn from 'classnames'
import s from './Footer.module.scss'
import { useRouter } from 'next/router'
import useLocale from '@/hooks/useLocale'
import Email from '@/icons/footer/email.svg'
import Linkedin from '@/icons/footer/Linkedin.svg'
import Telegram from '@/icons/footer/Telegram.svg'
import Facebook from '@/icons/footer/facebook.svg'
import Twitter from '@/icons/footer/twitter.svg'
import Link from 'next/link'
import Image from 'next/image'

type Iprops = {
  type?: number | string //1代表是home页面样式
  grandRef: any
}
const Footer: React.FC<Iprops> = ({ type, grandRef }) => {
  const router = useRouter()
  const { t } = useLocale()

  const [openSections, setOpenSections] = useState({
    products: true,
    solutions: false,
    insights: false,
    company: false,
    customer: false
  })

  const [isFirstProducts, setIsFirstProducts] = useState(true)
  const currentYear = new Date().getFullYear()


  const toggleSection = useCallback((section: keyof typeof openSections) => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }))
    if (section === 'products' && isFirstProducts) {
      setIsFirstProducts(false)
    }
  }, [isFirstProducts])


  const onExpand = useCallback(
    (type: keyof typeof openSections) => {
      toggleSection(type)
    },
    [toggleSection]
  )


  const productList = useMemo(() => {
    const a = {
      software: {
        title: t('Software-as-a-Service'),
        list: [
          {
            title: t('Digital Assets Exchange'),
            link: '/product/exchange'
          },
          {
            title: t('Decentralized Exchange'),
            link: '/product/dex'
          },
          {
            title: t('Liquidity-as-a-service'),
            link: '/product/liquidity'
          },
          {
            title: t('Wallet-as-a-service'),
            link: '/product/wallet'
          },
          {
            title: t('NFT Marketplace'),
            link: '/product/nft'
          },
          {
            title: t('Know-Your-Transaction KYT'),
            link: '/product/kyt'
          },
        ]
      },
      blockchain: {
        title: t('Blockchain for Financial Services'),
        list: [
          {
            title: t('Asset Tokenization'),
            link: '/solution/AssetTokenization'
          },
          {
            title: t('Gaming and Metaverse'),
            link: '/solution/GamingandMetaverse'
          },

          {
            title: t('Smart Web3 Banking'),
            link: '/solution/SmartWeb3Banking'
          }
        ]
      },
      asset: {
        title: t('Asset Custody'),
        list: [
          {
            title: t('ChainUp Custody'),
            link: '/product/custody'
          }
        ]
      },
      assetManage: {
        title: t('Asset Management'),
        list: [
          {
            title: t('ChainUp Investment'),
            link: '/product/investment'
          }
        ]
      },
      web3: {
        title: t('Web3 Infrastructure'),
        list: [
          {
            title: t('Crypto Mining'),
            link: '/product/crypto-mining'
          },
          {
            title: t('Staking'),
            link: 'https://cloud.chainup.com/'
          },
          {
            title: t('Blockchain API'),
            link: 'https://cloud.chainup.com/blockapi'
          },

        ]
      }
    }
    return a
  }, [t])

  const solutionList = useMemo(
    () => [
      {
        title: t('Central Banks'),
        link: '/solutions/central-banks'
      },
      {
        title: t('Commercial Banks'),
        link: '/solutions/commercial-banks'
      },
      {
        title: t('Retail Banks'),
        link: '/solutions/retail-banks'
      },
      {
        title: t('Investment Banks'),
        link: '/solutions/investment-banks'
      },
      {
        title: t('Digital Asset Exchanges'),
        link: '/solutions/digital-asset-exchanges'
      },
      {
        title: t('Asset Management Firms'),
        link: '/solutions/asset-management-firms'
      },
      {
        title: t('Payment Platforms'),
        link: '/solutions/payment-platforms'
      },
      {
        title: t('Hedge Funds'),
        link: '/solutions/hedge-funds'
      },
      {
        title: t('OTC/Investment Brokerage Platforms'),
        link: '/solutions/otc-investment-borakage-platforms'
      },
      {
        title: t('Venture Capital/Private Equity Firms'),
        link: '/solutions/venture-capital-private-equity-firms'
      }
    ],
    [t]
  )
  const insightList = useMemo(
    () => [
      {
        title: t('News'),
        link: '/news'
      },
      {
        title: t('Blog'),
        link: '/blog'
      },
      {
        title: t('Events'),
        link: '/events'
      },
      {
        title: t('Market Updates'),
        link: '/marketupdates'
      }
    ],
    [t]
  )
  const aboutUsList = useMemo(
    () => [
      {
        title: t('About'),
        link: '/about'
      },
      {
        title: t('Contact Us'),
        link: '/Contact_us'
      },
      {
        title: t('Leadership'),
        link: '/team'
      },
      {
        title: t('Careers'),
        link: '/job'
      }
    ],
    [t]
  )

  const socialItems = useMemo(
    () => [
      {
        link: 'mailto:info@chainup.com',
        icon: <Email />,
        label: 'footer mailto'
      },
      {
        link: 'https://www.linkedin.com/company/ChainUP-Technology/',
        icon: <Linkedin />,
        label: 'footer linkedin'
      },
      {
        link: 'https://www.facebook.com/ChainUPTechnology/',
        icon: <Facebook />,
        label: 'footer facebook'
      },
      {
        link: 'https://twitter.com/ChainUPOfficial',
        icon: <Twitter />,
        label: 'footer twitter'
      },
      {
        link: 'https://t.me/ChainUp2022',
        icon: <Telegram />,
        label: 'footer me'
      }
    ],
    []
  )

  return (
    <div className={cn(s.footerWarpper, { [s.footerWarpperHome]: type == 1 })} id="footer" ref={grandRef}>
      <div className={s.content}>
        <div className={cn(s.menuWarpper, s.socilaWarpper)}>
          <div className={s.socilaImg} data-aos="fade-up">
            <Image src="/images/home/header/logo_white.svg" layout="fill" alt=""></Image>

          </div>
          <div className={s.certification_text} data-aos="fade-up">
            {t('Certifications')}
          </div>
          <div className={s.sco_img} data-aos="fade-up">
            <Link href="/news/ChainUp-Earns-Best-Institutional-Custody-Asset-Service-Recognition-2023">
              <a target={'_blank'} aria-label="logo">
                <Image src="/images/common/footer/RAlogo.svg" layout="fill" alt=""></Image>
              </a>
            </Link>
          </div>
          <div className={cn(s.certificate_warpper, s.certificate_warpper1)} data-aos="fade-up">
            <div className={s.ios_img}>
              <Link href="/news/ChainUp-now-SOC-2-Type-1-certified-Blockchain-Solutions-Provider">
                <a target={'_blank'} aria-label="type1">
                  <Image src="/images/home/banner/ios_type1.svg" layout="fill" alt=""></Image>
                </a>
              </Link>
            </div>
            <div className={s.ios_img}>
              <Link href="/news/chainup-is-now-soc-2-type-2-certified">
                <a target={'_blank'} aria-label="type2">
                  <Image src="/images/home/banner/ios_type2.svg" layout="fill" alt=""></Image>
                </a>
              </Link>
            </div>
          </div>
          <div className={cn(s.certificate_warpper, s.certificate_warpper2)} data-aos="fade-up">
            <div className={s.ios_img}>
              <Link href="/news/ChainUp-ISO-IEC-27001-27017-27018-Certified-Blockchain-Solutions-Provider">
                <a target={'_blank'} aria-label="iso1">
                  <Image src="/images/home/banner/iso1.svg" layout="fill" alt=""></Image>
                </a>
              </Link>
            </div>
            <div className={s.ios_img}>
              <Link href="/news/ChainUp-ISO-IEC-27001-27017-27018-Certified-Blockchain-Solutions-Provider">
                <a target={'_blank'} aria-label="iso2">
                  <Image src="/images/home/banner/iso2.svg" layout="fill" alt=""></Image>
                </a>
              </Link>
            </div>
            <div className={s.ios_img}>
              <Link href="/news/ChainUp-ISO-IEC-27001-27017-27018-Certified-Blockchain-Solutions-Provider">
                <a target={'_blank'} aria-label="iso3">
                  <Image src="/images/home/banner/iso3.svg" layout="fill" alt=""></Image>
                </a>
              </Link>
            </div>
          </div>

          <div className={s.link_warpper}>
            {socialItems.map((item, index) =>
              item.link == 'mailto:info@chainup.com' ? (
                <div
                  key={index}
                  data-aos="fade-up"
                  onClick={() => {
                    window.location.href = 'mailto:info@chainup.com'
                  }}
                >
                  {item.icon}
                </div>
              ) : (
                <a data-aos="fade-up" target={item.link.indexOf('http') != -1 ? '_blank' : '_self'} key={index} href={item.link} rel="preconnect noreferrer" aria-label={item.link}>
                  {item.icon}
                </a>
              )
            )}
          </div>
        </div>

        <div className={cn(s.solutionsWarpper)}>
          <div className={cn({ [s.openWarpper]: openSections.products })}>
            <div
              className={cn(s.menuTitle, s.solutionsTitle)}
              data-aos="fade-up"
              onClick={() => {
                onExpand('products')
              }}
            >
              <span>{t('Products')}</span>
              <div className={s.isOpen}></div>
            </div>
            <div className={cn(s.solutions, s.itemOpen, { [s.itemOpen_first]: isFirstProducts })}>
              <div>
                <div className={s.itemTitle} data-aos="fade-up">
                  {productList.software.title}
                </div>
                {productList.software.list.map((item, index) => (
                  <Link key={index} href={item.link} >
                    <a className={s.solutionsItem} data-aos="fade-up" target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                      {item.title}
                    </a>
                  </Link>
                ))}
              </div>
              <div className={s.itemTitle} data-aos="fade-up">
                {productList.blockchain.title}
              </div>

              {productList.blockchain.list.map((item, index) => (
                <Link key={index} href={item.link} >
                  <a className={s.solutionsItem} data-aos="fade-up" target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                    {item.title}
                  </a>
                </Link>
              ))}

              <div className={s.itemTitle} data-aos="fade-up">
                {productList.asset.title}
              </div>

              {productList.asset.list.map((item, index) => (
                <Link key={index} href={item.link} >
                  <a className={s.solutionsItem} data-aos="fade-up" target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                    {item.title}
                  </a>
                </Link>
              ))}
              <div className={s.itemTitle} data-aos="fade-up">
                {productList.assetManage.title}
              </div>

              {productList.assetManage.list.map((item, index) => (
                <Link key={index} href={item.link} >
                  <a className={s.solutionsItem} data-aos="fade-up" target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                    {item.title}
                  </a>
                </Link>
              ))}



              <div className={s.itemTitle} data-aos="fade-up">
                {productList.web3.title}
              </div>

              {productList.web3.list.map((item, index) => (
                <Link key={index} href={item.link} >
                  <a className={s.solutionsItem} data-aos="fade-up" target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                    {item.title}
                  </a>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className={cn(s.solutionsWarpper, s.solutionsWarpperWidth)}>
          <div className={cn({ [s.openWarpper]: openSections.solutions })}>
            <div
              className={cn(s.menuTitle, s.solutionsTitle)}
              data-aos="fade-up"
              onClick={() => {
                onExpand('solutions')
              }}
            >
              <span>{t('Solutions')}</span>
              <div className={s.isOpen}></div>
            </div>
            <div className={s.itemOpen}>
              {solutionList.map((item, index) => (
                <Link href={item.link} key={index} >
                  <a data-aos="fade-up" className={cn(s.solutionsItem)} target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                    {item.title}
                  </a>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className={s.insightsWarpper}>
          <div className={cn({ [s.openWarpper]: openSections.insights })}>
            <div
              className={s.menuTitle}
              data-aos="fade-up"
              onClick={() => {
                onExpand('insights')
              }}
            >
              <span>{t('Insights')}</span>
              <div className={s.isOpen}></div>
            </div>
            <div className={s.itemOpen}>
              {insightList.map((item, index) => (
                <Fragment key={index} >{
                  !(router.locale == 'en' || (router.locale == 'zh' && item.link != '/events')) ? <div data-aos="fade-up" className={s.solutionsItem} onClick={
                    () => {
                      window.location.href = '/' + 'en' + item.link
                    }
                  }>
                    {item.title}
                  </div> :
                    <Link href={item.link} >
                      <a data-aos="fade-up" className={s.solutionsItem} target={item.link.indexOf('http') != -1 ? '_blank' : '_self'} onClick={() => {
                      }}>
                        {item.title}
                      </a>
                    </Link>
                }</Fragment>


              ))}
            </div>
          </div>
          <div className={cn({ [s.openWarpper]: openSections.company })}>
            <div
              className={cn(s.menuTitle, s.companyTitle)}
              data-aos="fade-up"
              onClick={() => {
                onExpand('company')
              }}
            >
              <span>{t('Our Company')}</span>
              <div className={s.isOpen}></div>
            </div>
            <div className={s.itemOpen}>
              {aboutUsList.map((item, index) => (
                <Link href={item.link} key={index} >
                  <a data-aos="fade-up" className={s.solutionsItem} target={item.link.indexOf('http') != -1 ? '_blank' : '_self'}>
                    {item.title}
                  </a>
                </Link>
              ))}
            </div>
          </div>
          <div className={cn(s.customerWarpper, { [s.openWarpper]: openSections.customer }, s.customerWarpperPc)}>
            <div className={cn(s.menuTitle, s.companyTitle)} data-aos="fade-up">
              <span>{t('Customer Support')}</span>
              <div className={s.isOpen}></div>
            </div>
            <div className={s.itemOpen}>
              <Link href="/service-terms" >
                <a data-aos="fade-up" className={s.solutionsItem} target={'_self'}>
                  {t('Support Chat')}
                </a>
              </Link>
              <Link href="/service-terms">
                <a data-aos="fade-up" className={s.solutionsItem} target={'_self'}>
                  {t('Service Terms')}
                </a>
              </Link>
            </div>
          </div>
        </div>

        <div className={cn(s.customerWarpper, { [s.openWarpper]: openSections.customer }, s.customerWarpperh5)}>
          <div
            className={cn(s.menuTitle, s.companyTitle)}
            data-aos="fade-up"
            onClick={() => {
              onExpand('customer')
            }}
          >
            <span>{t('Customer Support')}</span>
            <div className={s.isOpen}></div>
          </div>
          <div className={s.itemOpen}>
            <Link href="/service-terms">
              <a data-aos="fade-up" className={s.solutionsItem} target={'_self'}>
                {t('Support Chat')}
              </a>
            </Link>
            <Link href="/service-terms">
              <a data-aos="fade-up" className={s.solutionsItem} target={'_self'}>
                {t('Service Terms')}
              </a>
            </Link>
          </div>
        </div>
      </div>
      <div className={s.socilaFooter} data-aos="fade-up">
        {t('Copyright © {{currentYear}} ChainUp. All rights reserved', { currentYear })}
        <Link href="/privacyPolicy">
          <a>&nbsp;&nbsp;{t('Privacy Policy')}&nbsp;&nbsp;</a>
        </Link>
        <Link href="/terms-of-service">
          <a>{t('Terms of Service')}</a>
        </Link>
      </div>
    </div >
  )
}

export default Footer
